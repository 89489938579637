import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TableCell, TableRow } from '@mui/material'
import ListingThumbnail from '../Shared/ListingThumbnail'

const Store = props => {
  return (
    <TableRow key={props.id}>
      <TableCell component="th" scope="row"><Link to={`/admin/stores/${props.id}`}>{props.index}</Link></TableCell>
      <ListingThumbnail image={props.logo}/>
      <TableCell component="th" scope="row"><Link to={`/admin/stores/${props.id}`}>{props.name}</Link></TableCell>
      <TableCell component="th" scope="row">{props.company_name}</TableCell>
      <TableCell>{props.is_live ? '✔' : ''}</TableCell>
    </TableRow>
  )
}

Store.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  company_name: PropTypes.string,
  is_live: PropTypes.bool,
  logo: PropTypes.string,
  index: PropTypes.number,
}

export default Store
