import React from 'react';
import useTranslation from '../../hooks/useTranslation'
import PropTypes from 'prop-types';
import { Card, CardContent, CardActions, Button, Typography, IconButton, Grid, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.action.hover,
}));

const FulfillmentCenterCard = ({ center, onRemove, onActivate, onDeactivate, onEdit }) => {
  const { t } = useTranslation();

  const orderStatusLabels = {
    default: t('labels.fulfillment_centers.order_status_default'),
    processing: t('labels.fulfillment_centers.order_status_processing'),
    ready: t('labels.fulfillment_centers.order_status_ready'),
    complete: t('labels.fulfillment_centers.order_status_complete')
  };

  const renderActionButton = () => {
    if (center.StoreFulfillmentCenters.status === 'active') {
      return (
        <>
          <Button onClick={() => onDeactivate(center)} variant="contained" color="primary">
            {t('labels.fulfillment_centers.deactivate_button')}
          </Button>
          <Button disabled variant="contained" color="success">
            {t('labels.fulfillment_centers.activated_button')}
          </Button>
        </>
      );
    }
    return (
      <Button onClick={() => onActivate(center)} variant="contained" color="primary">
        {t('labels.fulfillment_centers.activate_button')}
      </Button>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{center.name}</Typography>
          <div>
            {center.StoreFulfillmentCenters.status === 'active' && (
              <IconButton onClick={() => onEdit(center)}>
                <EditIcon />
              </IconButton>
            )}
            <IconButton onClick={() => onRemove(center.id)}>
              <DeleteIcon style={{ color: 'black' }} />
            </IconButton>
          </div>
        </Grid>
        <Grid container gap={2} flexDirection={'row'} marginBottom={1}>
          <Typography variant="body2">{t('labels.fulfillment_centers.status_label')}:</Typography> <Chip size='small' label={center.StoreFulfillmentCenters.status}/>
        </Grid>
        <Grid container gap={2} flexDirection={'row'}>
          <Typography variant="body2">{t('labels.fulfillment_centers.apply_on_order_status')}:</Typography> <Chip size='small' label={orderStatusLabels[center.StoreFulfillmentCenters.applyOnOrderStatus]} />
        </Grid>
      </CardContent>
      <CardActions alignItems>
        {renderActionButton()}
      </CardActions>
    </StyledCard>
  );
};

FulfillmentCenterCard.propTypes = {
  center: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default FulfillmentCenterCard;
