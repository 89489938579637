import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Autocomplete, TextField, Divider } from '@mui/material';
import useTranslation from '../../hooks/useTranslation'
import FulfillmentCenterCard from './FulfillmentCenterCard';
import { useLogistics } from '../../components/Helpers/logisticsContext';

const AddFulfillmentCenters = ({ onFulfillmentCenterSelected, onRemoveFulfillmentCenterClicked, handleActivate, handleDeactivate, handleEdit }) => {
  const { t } = useTranslation();

  const { 
    fulfillmentCenters, 
    selectedFulfillmentCenter, 
    setSelectedFulfillmentCenter, 
    store,
  } = useLogistics();

  const formatFulfillmentCenterList = (centers) => {
    if (centers.length === 0) return [];
    
    let existingFulfillmentCenterIds = store?.fulfillmentCenters?.map((center) => center.id) || [];
    return centers
      .filter((center) => !existingFulfillmentCenterIds.includes(center.id))
      .map((center) => ({ label: center.name, id: center.id }));
  };

  return (
    <div>
      <Typography variant="h6">{t('labels.fulfillment_centers.add_fulfillment_centers_title')}</Typography>
      <Autocomplete
        disablePortal={true}
        inputValue={selectedFulfillmentCenter}
        onInputChange={(event, newValue, reason) => {
          if (reason === 'reset') {
            setSelectedFulfillmentCenter('');
          } else {
            setSelectedFulfillmentCenter(newValue);
          }
        }}
        onChange={(event, option) => option && onFulfillmentCenterSelected(option.id)}
        options={formatFulfillmentCenterList(fulfillmentCenters)}
        getOptionLabel={(option) => option.label ? option.label : ''}
        renderInput={(params) => <TextField {...params} label={t('labels.fulfillment_centers.fulfillment_centers_label')} variant="outlined" />}
      />
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2}>
        {store?.fulfillmentCenters?.map((center) => (
          <Grid item xs={12} md={6} key={center.id}>
            <FulfillmentCenterCard 
              center={center}
              onRemove={onRemoveFulfillmentCenterClicked}
              onActivate={handleActivate}
              onDeactivate={handleDeactivate}
              onEdit={handleEdit}
            />
          </Grid>
        ))}
        {store?.fulfillmentCenters?.length === 0 && (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>{t('labels.fulfillment_centers.no_fulfillment_centers_text')}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

AddFulfillmentCenters.propTypes = {
  onFulfillmentCenterSelected: PropTypes.func.isRequired,
  onRemoveFulfillmentCenterClicked: PropTypes.func.isRequired,
  handleActivate: PropTypes.func.isRequired,
  handleDeactivate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default AddFulfillmentCenters;