import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent } from '@mui/material';
import AddFulfillmentCenters from '../../components/Shared/AddFulfillmentCenters';

const FulfillmentCenterList = ({ onAddFulfillmentCenter, onRemoveFulfillmentCenter, onActivate, onDeactivate, onEdit }) => {

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <AddFulfillmentCenters
            onFulfillmentCenterSelected={onAddFulfillmentCenter}
            onRemoveFulfillmentCenterClicked={onRemoveFulfillmentCenter}
            handleActivate={onActivate}
            handleDeactivate={onDeactivate}
            handleEdit={onEdit}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

FulfillmentCenterList.propTypes = {
  onAddFulfillmentCenter: PropTypes.func.isRequired,
  onRemoveFulfillmentCenter: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default FulfillmentCenterList;