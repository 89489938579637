import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, RadioGroup, Radio, FormControlLabel, Grid, Button, TextField, FormHelperText } from '@mui/material';
import useTranslation from '../../hooks/useTranslation';
import { useLogistics } from '../../components/Helpers/logisticsContext';

const AuthenticationForm = ({ onAuthenticate, onRevokeCredentials }) => {
  const { t } = useTranslation();

  const { 
    applyOnOrderStatus, 
    setApplyOnOrderStatus, 
    customOrderStatus, 
    setCustomOrderStatus,
    dreamUsername,
    setDreamUsername,
    dreamPassword,
    setDreamPassword,
    isShelfless,
    showCredentials,
    showAuthenticationForm,
    setShowAuthenticationForm,
    authenticating
  } = useLogistics();

  const onClose = () => {
    setShowAuthenticationForm(false);
  };

  useEffect(() => {
    if (applyOnOrderStatus !== 'default' && applyOnOrderStatus !== 'customized') {
      setCustomOrderStatus(applyOnOrderStatus);
      setApplyOnOrderStatus('customized');
    }
  }, [applyOnOrderStatus, setApplyOnOrderStatus, setCustomOrderStatus]);

  return (
    <Dialog open={showAuthenticationForm} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('labels.fulfillment_authentication.dialog_title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {isShelfless
            ? t('labels.fulfillment_authentication.dialog_text_dream')
            : t('labels.fulfillment_authentication.dialog_text_order_status')}
        </DialogContentText>

        {/* Apply on Order Status */}
        <FormControl component="fieldset" style={{ marginTop: '16px', width: '100%' }}>
          <RadioGroup
            value={applyOnOrderStatus}
            onChange={(e) => setApplyOnOrderStatus(e.target.value)}
          >
            <FormControlLabel
              value="default"
              control={<Radio />}
              label={t('labels.fulfillment_authentication.default_label')}
            />
            <FormHelperText>
              {t('labels.fulfillment_authentication.default_helper_text')}
            </FormHelperText>
            <FormControlLabel
              value="customized"
              control={<Radio />}
              label={t('labels.fulfillment_authentication.custom_label')}
            />
            <FormHelperText>
              {t('labels.fulfillment_authentication.custom_helper_text')}
            </FormHelperText>
          </RadioGroup>

          {/* Custom Order Status Options */}
          {applyOnOrderStatus === 'customized' && (
            <RadioGroup
              value={customOrderStatus}
              onChange={(e) => setCustomOrderStatus(e.target.value)}
              style={{ marginTop: '16px', padding: '16px', border: '1px solid lightgrey', borderRadius: '4px' }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} style={{ paddingBottom: '8px' }}>
                  <FormControlLabel
                    value="processing"
                    control={<Radio />}
                    label={t('labels.fulfillment_authentication.status_created_label')}
                  />
                  <FormHelperText>
                    {t('labels.fulfillment_authentication.status_created_helper_text')}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={4} style={{ paddingBottom: '8px' }}>
                  <FormControlLabel
                    value="ready"
                    control={<Radio />}
                    label={t('labels.fulfillment_authentication.status_ready_label')}
                  />
                  <FormHelperText>
                    {t('labels.fulfillment_authentication.status_ready_helper_text')}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={4} style={{ paddingBottom: '8px' }}>
                  <FormControlLabel
                    value="complete"
                    control={<Radio />}
                    label={t('labels.fulfillment_authentication.status_completed_label')}
                  />
                  <FormHelperText>
                    {t('labels.fulfillment_authentication.status_completed_helper_text')}
                  </FormHelperText>
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        </FormControl>

        {/* Shelfless Credentials */}
        {isShelfless && !showCredentials && (
          <Button
            onClick={onRevokeCredentials}
            color="primary"
            variant="outlined"
            style={{ marginTop: '16px' }}
          >
            {t('labels.fulfillment_authentication.revoke_credentials')}
          </Button>
        )}

        {isShelfless && showCredentials && (
          <>
            <TextField
              label={t('labels.fulfillment_authentication.username_label')}
              value={dreamUsername}
              onChange={(e) => setDreamUsername(e.target.value)}
              fullWidth
              style={{ marginTop: '16px', marginBottom: '8px' }}
              required
            />
            <TextField
              label={t('labels.fulfillment_authentication.password_label')}
              type="password"
              value={dreamPassword}
              onChange={(e) => setDreamPassword(e.target.value)}
              fullWidth
              style={{ marginTop: '16px', marginBottom: '8px' }}
              required
            />
          </>
        )}
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('labels.fulfillment_authentication.cancel_button')}
        </Button>
        <Button
          disabled={authenticating}
          onClick={onAuthenticate}
          variant="contained"
          color="primary"
        >
          {authenticating
            ? t('labels.fulfillment_authentication.authenticating')
            : t('labels.fulfillment_authentication.activate_button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AuthenticationForm.propTypes = {
  onAuthenticate: PropTypes.func.isRequired,
  onRevokeCredentials: PropTypes.func.isRequired,
};

export default AuthenticationForm;